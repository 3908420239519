import React from "react"
import { StaticQuery, graphql } from "gatsby"
import SocialLink from "../components/social-link"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGithub,
  faLinkedinIn,
  faMedium,
  faTwitter,
  faInstagram
} from '@fortawesome/free-brands-svg-icons'

export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            home {
              title
              description
              subtitle
            }
            social {
              github
              linkedin
              medium
              twitter
              instagram
            }
          }
        }
      }
    `}
    
    render={data => (
      <div className="hero-header">
        <div class="avatar rounded">
          <img class="rounded" alt={data.site.siteMetadata.home.title} src="/assets/nv.jpg" />
        </div>
        <h1 className="headline">{data.site.siteMetadata.home.title}</h1>
        <h2 className="subline">{data.site.siteMetadata.home.subtitle}</h2>
        <p
          className="primary-content" 
          dangerouslySetInnerHTML={{ __html: data.site.siteMetadata.home.description}}
        />
        <div className="social-links">
          <SocialLink href={data.site.siteMetadata.social.github} title="github">
            <FontAwesomeIcon icon={faGithub} size="2x"/>
          </SocialLink>
          <SocialLink href={data.site.siteMetadata.social.medium} title="medium">
            <FontAwesomeIcon icon={faMedium} size="2x"/>
          </SocialLink>
          <SocialLink href={data.site.siteMetadata.social.twitter} title="twitter">
            <FontAwesomeIcon icon={faTwitter} size="2x"/>
          </SocialLink>
          <SocialLink href={data.site.siteMetadata.social.linkedin} title="linkedin">
            <FontAwesomeIcon icon={faLinkedinIn} size="2x"/>
          </SocialLink>
          <SocialLink href={data.site.siteMetadata.social.instagram} title="instagram">
            <FontAwesomeIcon icon={faInstagram} size="2x"/>
          </SocialLink>
        </div>
      </div>
    )}
  />
)